<template>
  <base-main-container>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="68">
        AI is preparing your resource
      </v-progress-circular>
    </v-overlay>

    <template v-slot:centered>
      <v-form>
        <v-card class="my-card rounded-lg" elevation="0">
          <v-card-title>LessonLaunch : Ideas to start your lesson </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="topic"
              class="mt-4 ml-2"
              label="Topic/Subject"
              outlined
              :rules="[rules.required]"
            />
            <v-text-field
              v-model="performanceIndicator"
              class="ml-2"
              label="Performance indicator / Specific learning objectives"
              hint="Optional"
              outlined
            />
            <v-select
              class="ml-2"
              append-icon="mdi-filter"
              v-model="grade"
              :items="['K','1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11','12']"
              label="Suitable for Grade level"
              outlined
              clearable
            />

          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn class="mr-2" outlined link to="newresource">
              <v-icon class="mr-2"> mdi-backspace</v-icon> Cancel
            </v-btn>
 
            <v-btn :disabled="!topic" color="#212121" @click="sendRequest()">
              <h3 v-if="!topic">Generate please</h3>
              <h3 v-else style="color: white">Generate please</h3>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </template>
    <v-snackbar v-model="showSnackbar" :timeout="6000" color="warning" top>
      {{ snackbarMessege }}
    </v-snackbar>
  </base-main-container>
</template>

<script>
import { getFunctions, httpsCallable } from "firebase/functions";

import { firebaseAuth } from "../../firebase";
import { db } from "../../firebase";

export default {
  data() {
    return {
      what: "Lesson Launching Ideas",
      topic: null,
      grade: null,
      performanceIndicator: null,
      isLoading: false,

      resources: [],
      showSnackbar: false,
      snackbarMessege: "",
      rules: {
        required: (value) => !!value || "Required.",
        max: (v) => v <= 50 || "Max 50 items",
        wholeNum: (v) => {
          const intRegex = /^\d+$/;
          return intRegex.test(v) || "Please enter a valid whole number";
        },
      },
    };
  },
  // computed: {
  //   premium() {
  //     return this.$store.state.subscription
  //       ? this.$store.state.subscription.status == "active" ||
  //           this.$store.state.subscription.status == "active school" ||
  //           this.$store.state.subscription.status == "trialing"
  //       : false;
  //   },
  // },

  methods: {
    async sendRequest() {
      this.isLoading = true;

      this.performanceIndicator = this.performanceIndicator
        ? this.performanceIndicator
        : "no specific performace indicator or learning objective";
      this.grade = this.grade
        ? "Yes, this quiz should be suitable for grade level: " + this.grade
        : "no specific grade level";
      
      let uid = firebaseAuth.currentUser.uid;
      let newresource = {
        what: this.what,
        topic: this.topic,
        grade: this.grade,
        lastsaved: Date.now(),
        promt: [{role: "system",content: "You are an excellent educator and teacher."},
          {role: "user", content:"I need help ideas to launch my lesson, something that hooks the students and engage them in the class"},
          {role: "assistant",content:"What is the topic you will be teaching"},
          { role: "user", content: this.topic },
          {role: "assistant",content:"Thank you for letting me know the topic. Do you have any specific learning objectives or performance indicators"},
          { role: "user", content: this.performanceIndicator },
          {role: "assistant",content:"Thank you. Do you need lesson launch ideas for a specific grade level?" },
          { role: "user", content: this.grade },
          {role: "assistant",content:"Thank you, I have all the details that I need to ideas. Do you have any other details before I generate the resorce for you?"},
          {role: "user",content: "No, that is all, please give me ideas to get msy students attention when starting this lesson"}
        ],
          
        uid: uid,
      };
      try {
        const userRef = db.collection("customers").doc(uid);
        const subcollectionRef = userRef.collection("saved");

        const docRef = await subcollectionRef.add(newresource);
        //console.log(docRef.id);
        newresource.id = docRef.id;
      } catch (error) {
        console.error("Error adding document:", error);
      }

      const functions = getFunctions();

      try {
        const functionRef = httpsCallable(functions, "callopenai");
        functionRef(newresource);

        this.isLoading = false;

        this.$router.push("/mydocs");
      } catch (error) {
        this.isLoading = false;
        this.showSnackbar = true;
        this.snackbarMessege = "The system is overloaded, please try again";
      }
    },

    clearSelections() {
      this.topic = null;
      this.grade = null;
    }

   
  },
};
</script>

<style scoped>
@media (min-width: 600px) {
  .my-card {
    width: 300px;
  }
}
@media (min-width: 960px) {
  .my-card {
    width: 500px;
  }
}
@media (min-width: 1264px) {
  .my-card {
    width: 800px;
  }
}
</style>




